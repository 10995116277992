/* General Styles */
* {
	box-sizing: border-box;
  }
  
  body {
	font-family: Verdana, sans-serif;
	margin: 0 auto;
	min-width: 1000px;
  }
  
  a {
	text-decoration: none;
  }
  
  h1 {
	color: #068;
	margin: 0;
	padding: 0;
	text-align: center;
	font-family: Helvetica, Arial, sans-serif;
	font-variant: small-caps;
	font-weight: bold;
	font-size: 1.5rem;
  }
  
  .h1-hebrew {
	font-size: 1.6rem;
  }
  
  h2 {
	font-family: Helvetica, Arial, sans-serif;
	font-variant: small-caps;
	text-indent: 0;
	color: white;
	background-color: #068;
	font-size: 1.15rem;
	text-align: center;
	border-radius: 20px 0;
	padding: 5px 10px;
  }
  
  h3 {
	font-family: Helvetica, Arial, sans-serif;
	background-color: rgb(209, 224, 224);
	font-size: 1.1rem;
	text-indent: 25px;
	text-align: center;
  }
  
  .p-about-registr {
	font-size: 1em;
	text-align: center;
	text-indent: 0;
  }
  
  .logo {
	margin-bottom: 0;
	overflow: auto;
  }
  
  .logo_img {
	float: left;
	width: 150px;
	border-radius: 5px;
	padding: 2px;
	box-shadow: none;
  }
  
  .div_main {
	margin: auto;
	width: 70%;
	min-width: 1000px;
	padding: 50px 0;
	background-color: white;
	text-align: justify;
	overflow-wrap: break-word;
	overflow: auto;
	text-indent: 30px;
  }
  
  .div_main li {
	text-indent: 0;
  }
  
  .div_item_sostav {
	padding: 5px;
	margin: 5px;
	overflow: auto;
	border-bottom: 1px solid #ccc;
  }
  
  img {
	border-radius: 6px;
	box-shadow: 3px 3px 8px grey;
  }
  
  .image1 {
	width: 100%;
	height: 130px;
	padding: 0;
	border-radius: 0;
	box-shadow: 2px 2px 8px gray;
  }
  
  .image_sostav {
	float: left;
	height: 120px;
	margin-right: 15px;
	margin-left: 15px;
	box-shadow: 3px 3px 8px grey;
  }
  
  .divmenu {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-top: 1px solid aquamarine;
	width: 100%;
	position: fixed;
	height: 50px;
	margin: auto;
	background: #068;
	padding: 10px;
	z-index: 999;
  }
  
  .logo-in-menu {
	display: block;
  }
  
  #burger-menu {
	display: none;
	font-size: 1.6rem;
	color: #068;
  }
  
  .nav_menu {
	display: flex;
	align-items: center;
	width: 100%;
	margin: auto;
	text-indent: 1px;
	text-align: center;
	justify-content: space-between;
  }
  
  .nav_menu ul {
	list-style: none;
	margin: auto;
  }
  
  .nav_menu a {
	text-decoration: none;
	outline: none;
  }
  
  .topmenu {
	color: white;
	display: flex;
	list-style: none;
	padding: 0;
	margin: 0;
	flex-grow: 1;
	justify-content: center;
	flex-direction: row;
  }
  
  .topmenu > li {
	margin: 0 15px;
	position: relative;
	display: flex;
	align-items: center;
  }
  
  .topmenu > li > a {
	padding: 0;
	color: black;
  }
  
  .topmenu li a:hover {
	color: #e6855f;
  }
  
  .topmenu li:hover {
	color: #e6855f;
  }
  
  .submenu {
	display: none;
	position: absolute;
	white-space: nowrap;
	top: 100%;
	left: 0;
	background: rgba(255, 255, 255, 0.99);
	list-style: none;
	padding: 10px;
	margin: 0;
	text-align: left;
	border: 1px solid rgba(200, 200, 200, 0.99);
	box-shadow: 3px 3px 8px grey;
  }
  
  .topmenu > li:hover .submenu {
	display: block;
  }
  
  .submenu li a {
	color: black;
	line-height: 30px;
	padding: 0px 2px;
  }
  
  .parent {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	font-size: 0.9rem;
	margin-top: 5px;
	margin-bottom: 0;
	overflow: auto;
	align-items: stretch;
  }
  
  .parent > div {
	width: 24%;
	min-height: 350px;
	margin: 0.5%;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 15px 23px 23px 15px;
	overflow: auto;
	background-color: #fff;
  }
  
  .parent > div > .col_body {
	margin: 0;
	padding: 0;
	overflow: auto;
	text-align: left;
  }
  
  .col_body ul {
	margin-top: 0;
	padding: 0 0 0 7%;
	text-indent: 0;
  }
  
  .parent > div > .col_head {
	font-size: 1.05rem;
	font-weight: bold;
	text-align: center;
	color: #068;
	padding-bottom: 5px;
	margin: 0 0 2%;
	border-bottom: 1px solid #ccc;
	overflow: auto;
  }
  
  .col_head figure {
	margin: 0;
	padding: 0;
  }
  
  .col_head img {
	width: 10%;
	margin: 0 2%;
	box-shadow: none;
	float: left;
  }
  
  .parent a {
	color: #068;
  }
  
  .div_academy {
	overflow: auto;
  }
  
  .div-presidium-container {
	display: flex;
	flex-direction: column;
  }
  
  .div-presidium-row {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	flex-wrap: wrap;
  }
  
  .figure-presidium-item {
	width: 400px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
  }
  
  .figure-presidium-item figcaption {
	flex-grow: 1;
	text-align: left;
	text-indent: 0;
  }
  
  .figure-presidium-item h3 {
	margin-top: 0;
	text-align: left;
	text-indent: 0;
  }
  
  .image-presidium {
	height: 120px;
	margin-right: 15px;
	margin-left: 15px;
	box-shadow: 3px 3px 8px grey;
  }
  
  .footer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	overflow: auto;
	padding: 2px;
	text-indent: 0;
	border: 1px solid #bbb;
	color: rgb(200, 200, 200);
	background-color: rgb(0, 128, 128);
  }
  
  .footer > div {
	padding: 1% 5%;
  }
  
  .footer a {
	color: rgb(200, 200, 200);
  }
  
  .table_brd {
	border: 1px solid #ccc;
	text-indent: 0;
  }
  
  .table_brd td,
  th {
	border: 1px solid #ccc;
	padding: 20px;
  }
  
  .table_oglavl,
  .table_oglavl td,
  th {
	border: none;
	text-indent: 0;
  }
  
  .development {
	padding: 5%;
	margin: 7%;
	font-size: 45px;
  }
  
  iframe {
	margin: 10px;
	overflow: auto;
  }
  
  .span_pb {
	font-weight: bold;
	font-size: 1.1rem;
	font-variant: normal;
  }
  
  .fig_publ,
  .fig_publ img {
	width: 75%;
	text-align: center;
	box-shadow: none;
  }
  
  .fig_capt_under {
	display: flex;
	flex-flow: column;
  }
  
  .fig_capt_under > figcaption {
	font-style: italic;
	font-size: 0.9rem;
	text-indent: 0;
	text-align: center;
  }
  
  .fig_capt_under > img {
	height: 350px;
	object-fit: contain;
	padding: 0px;
  }
  
  .figure_link {
	display: flex;
	flex-flow: column;
  }
  
  .figure_link caption {
	font-style: italic;
	font-size: 0.9rem;
	text-indent: 0;
	text-align: center;
  }
  
  .figure_link img {
	height: 120px;
	object-fit: contain;
	padding: 0px;
	box-shadow: none;
  }
  
  .path_nav {
	margin: 5px;
	display: flex;
	flex-direction: row;
  }
  
  .table-bank {
	text-indent: 0;
	text-align: left;
	border: 1px solid rgb(240, 240, 240);
  }
  
  .table-bank tr:nth-of-type(odd) {
	background-color: rgb(240, 240, 240);
  }
  
  .table-bank td {
	padding: 10px 5px;
  }
  
  @media (max-width: 800px) {
	body {
	  min-width: 360px;
	}
  
	.div_main {
	  min-width: 360px;
	  text-align: left;
	}
  
	.div_item_sostav {
	  text-indent: 0;
	}
  
	ul li {
	  text-indent: 0;
	}
  
	.nav_menu,
	.topmenu {
	  overflow: auto;
	}
  
	#burger-menu {
	  display: inline-block;
	}
  
	#burger-menu:hover {
	  cursor: pointer;
	}
  
	.topmenu {
	  display: none;
	}
  
	.nav_menu ul.submenu {
	  visibility: visible;
	  position: static;
	  width: 100%;
	  list-style: square;
	}
  
	.submenu li::first-letter {
	  color: red;
	}
  
	.logo {
	  display: none;
	}
  
	.image1 {
	  display: none;
	}
  
	div.parent {
	  display: none;
	}
  
	h1 {
	  font-size: 1.1rem;
	  text-align: left;
	  text-indent: 0;
	}
  
	h3 {
	  text-indent: 0;
	  text-align: left;
	  padding: 2px 10px;
	}
  
	.h1-hebrew {
	  font-size: 1rem;
	}
  
	.span-before {
	  display: none;
	}
  
	.div-header {
	  padding: 5px 5px 1px;
	  margin-bottom: 5px;
	  margin-top: 5px;
	  background-color: cornsilk;
	}
  
	.div_academy {
	  margin: 5px;
	}
  
	.p-about-registr {
	  text-align: left;
	}
  }
  
  /* Additional styles */
  .logo img {
	width: 150px;
	height: auto;
  }
  
  .header-quote {
	font-size: 11pt;
	font-family: sans-serif;
	margin: 0;
	text-align: right;
	padding: 0;
	font-style: italic;
	font-weight: bold;
  }
  
  .header-title {
	margin-bottom: 5px;
	padding: 5px 5px 1px;
  }
  
  .header-title h1 {
	margin: 0;
	padding: 5px 0;
  }
  
  .header-title h1:nth-child(2) {
	color: darkcyan;
  }
  
  .header-title .h1-hebrew {
	font-size: 1.6rem;
  }
  
  @media (max-width: 800px) {
	.header-title h1 {
	  font-size: 1.1rem;
	  text-align: left;
	  text-indent: 0;
	}
  
	.header-title .h1-hebrew {
	  font-size: 1rem;
	}
  }
  
  .table-bank {
	width: 100%;
	border-collapse: collapse;
  }
  
  .table-bank th {
	border: 1px solid black;
	padding: 10px;
  }
  
  .marg-top-10 {
	margin-top: 10px;
  }
  
  .div_main {
	margin: 0 auto;
	padding: 20px;
  }
  
  .div_academy {
	background-color: #f9f9f9;
	padding: 20px;
	margin-bottom: 20px;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .div_item_sostav {
	margin-bottom: 20px;
  }
  
  ol.marg-top-10 li {
	margin-top: 10px;
  }
  
  ol.sub-counters {
	counter-reset: section;
	list-style-type: none;
  }
  
  ol.sub-counters > li::before {
	counter-increment: section;
	content: counters(section, ".") ".";
  }
  
  ol.sub-counters {
	margin-top: 10px;
  }
  
  ol.sub-counters li {
	margin-top: 5px;
  }
  
  ol.sub-counters li p {
	margin: auto 40px;
  }
  
  .note {
	position: relative;
  }
  
  .note-sign {
	color: red;
  }
  
  .note-sign:hover {
	cursor: pointer;
  }
  
  .note-sign:hover::before {
	color: black;
	content: "This achievement was possible solely thanks to the efforts of Dr. K. Levkov (INARN and NTA EI) and the prompt actions of our General Director S. Krimberg";
	display: block;
	position: absolute;
	background-color: antiquewhite;
	border: 1px solid grey;
	border-radius: 5px;
	padding: 5px;
	box-shadow: 1px 1px 1px grey;
  }
  
  @media (max-width: 800px) {
	.progr_conf {
	  display: none;
	}
  }
  
  .footer {
	padding: 20px;
	background-color: darkcyan;
	text-align: center;
  }
  
  .footer div {
	margin-bottom: 10px;
  }
  
  .footer img {
	margin: 5px;
  }
  
  .footer a {
	color: #000;
	text-decoration: none;
  }
  
  .footer a:hover {
	text-decoration: underline;
  }
  
  .login-li {
	margin-left: auto;
	display: flex;
	align-items: center;
  }
  
  .login-button {
	background-color: #007bff;
	color: white;
	border: none;
	border-radius: 5px;
	padding: 10px 20px;
	cursor: pointer;
	transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
	background-color: #0056b3;
  }

 
  